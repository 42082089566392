<template>
  <section>
    <section class="navBack">
      <v-container class="container-custom">
        <v-row>
          <v-col md="12" class="topNav">
            <ul class="navList">
              <li>Home</li>
              <li><v-icon> mdi-chevron-right </v-icon> Sell your vehicle</li>
            </ul>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <AddProduct :userSell="true" />
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import AddProduct from "@/components/business/product/addProduct";
export default {
  name: "SellYourCar",
  components: {
    AddProduct,
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
.pagination {
  list-style: none;
  padding-left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  .v-icon {
    font-size: 18px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}
.titleAction {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  a {
    text-decoration: none;
  }
}
.navList {
  padding-left: 0;
  list-style: none;
  display: flex;
  font-size: 14px;
  li {
    .v-icon {
      font-size: 18px;
      margin-left: 5px;
    }
    // margin-right: 15px;
  }
}
</style>